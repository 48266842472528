// These functions may be potentially innaccurate in certain locales that use differing amounts of characters in different cases

/**
 * Tests if a string includes another if casing is ignored
 * @param thisString The string to test
 * @param otherString The potentially included string being tested for
 */
export function stringWeaklyIncludes(thisString: string, otherString: string) {
	return thisString.toLocaleLowerCase().includes(otherString.toLocaleLowerCase());
}

/**
 * Tests if a string starts with another if casing is ignored
 * @param thisString The string to test
 * @param otherString The potentially included string being tested for
 */
export function stringWeaklyStartsWith(thisString: string, otherString: string) {
	return thisString.toLocaleLowerCase().startsWith(otherString.toLocaleLowerCase());
}

/**
 * Tests if a string ends with another if casing is ignored
 * @param thisString The string to test
 * @param otherString The potentially included string being tested for
 */
export function stringWeaklyEndsWith(thisString: string, otherString: string) {
	return thisString.toLocaleLowerCase().endsWith(otherString.toLocaleLowerCase());
}

/**
 * Converts the first character in a string to upper-case. Will not work for all languages (for example Dutch).
 *
 * @export
 * @param {string} thisString
 * @returns
 */
export function firstCharacterToUpper(thisString: string) {
	if (!thisString) {
		return '';
	}

	let newString = thisString.substring(0, 1).toLocaleUpperCase();

	if (thisString.length > 1) {
		newString += thisString.substring(1);
	}

	return newString;
}

/**
 * Converts the first character in a string to upper-case. Will not work for all languages (for example Dutch).
 *
 * @export
 * @param {string} thisString
 * @returns
 */
export function firstCharacterToLower(thisString: string) {
	if (!thisString) {
		return '';
	}

	let newString = thisString.substring(0, 1).toLocaleLowerCase();

	if (thisString.length > 1) {
		newString += thisString.substring(1);
	}

	return newString;
}

// https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
export function padStart(value, targetLength, padString) {
	// tslint:disable-next-line:no-bitwise
	targetLength = targetLength >> 0; // truncate if number, or convert non-number to 0;
	padString = String(typeof padString !== 'undefined' ? padString : ' ');
	if (value.length >= targetLength) {
		return String(value);
	} else {
		targetLength = targetLength - value.length;
		if (targetLength > padString.length) {
			padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
		}
		return padString.slice(0, targetLength) + String(value);
	}
}

/**
 * Converts a string from " Something Like  This" to "something-like-this"
 * @param value The string to convert
 */
export function toKebabCase(value: string) {
	if (!value || !value.trim()) {
		return '';
	}

	return value
		.trim()
		.replace(/[^a-zA-Z0-9]+/g, '-')
		.replace(/([a-z0-9])([A-Z])/g, '$1-$2')
		.replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
		.replace(/-$/, '')
		.toLocaleLowerCase();
}

/**
 * Converts a string from PascalCase, camelCase, snake_case, or space separated to camelCase. Beginning and trailing whitespace are trimmed.
 * @param value The string to convert
 */
export function toCamelCase(value: string) {
	return value
		.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		})
		.replace(/(?:\s+|[-_])/g, '');
}

/**
 * Removes html characters
 *
 * @export
 * @param {*} html
 * @returns
 */
export function stripHtml(html) {
	const tmp = document.createElement('div');
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || '';
}

/**
 * Cuts of text and adds ellipsis (...) after a certain number of characters
 * @param text The text to format
 * @param cutoff The number of characters to add the ellipsis after
 */
export function addEllipsis(text: string, cutoff: number = 20) {
	if (!text || text.length < cutoff) {
		return text;
	}

	return text.substring(0, cutoff) + '...';
}

/**
 * Trims a string value
 */
export function trimString(value: string | undefined) {
	return value ? value.trim() : '';
}

/**
 * Trims and joins the truthy strings from the provided array with the provided separator
 */
export function joinTrimmedStrings(separator: string, ...strings: (string | undefined)[]) {
	return strings
		?.filter((s) => s)
		.map(trimString)
		.join(separator);
}

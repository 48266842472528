import { ServerData } from 'services/ServerData';

export default {
	BaseUrl: ServerData.ACCOUNT_URL_BASE ?? '/my-account',
	Login: ServerData.ACCOUNT_LOGIN_URL ?? '/my-account/login',
	Login2fa: ServerData.ACCOUNT_LOGIN_WITH_2FA_URL ?? '/my-account/login-with-2fa',
	Register: ServerData.ACCOUNT_REGISTER_URL ?? '/my-account/register',
	ForgotPassword: ServerData.ACCOUNT_FORGOT_PASSWORD_URL ?? '/my-account/forgot-password',
	LoginWithRecoveryCode: ServerData.ACCOUNT_LOGIN_WITH_RECOVERY_CODE_URL ?? '/my-account/login-with-recovery-code',
	Logout: ServerData.ACCOUNT_LOGOUT_URL ?? '/my-account/logout',
	// #region Commerce
	OrganizationReview: ServerData.ACCOUNT_ORGANIZATION_REVIEW_URL ?? '/my-account/organization-review',
	OrganizationSelector: ServerData.ACCOUNT_ORGANIZATION_SELECTOR_URL ?? '/my-account/organization-selector',
	// #endregion
};

/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classnames from 'classnames';
import React from 'react';
import { WithTestId } from 'shared-client/types/test';
import StarEmptySVG from 'shared/components/svg/StarEmptySVG';
import StarSVG from 'shared/components/svg/StarSVG';
import useStarRating from './useStarRating';

/**
 * Product Rating Props
 */
interface RatingProps extends WithTestId {
	value: number | null;
	name: string;
	readonly?: boolean;
	onChange?: (rating: number) => void;
	maxRating?: number;
}

/**
 * If readonly, displays the rating with blank and filled stars (1-maxRating)
 * If not readonly, displays the rating picker with blank and filled stars (1-maxRating)
 * @param props
 */
export default function StarRating(props: RatingProps) {
	const { value, name, readonly = false, onChange, maxRating = 5, testId } = props;

	const { ratingStars, setRatingHovered } = useStarRating({
		value,
		name,
		maxRating,
	});

	if (!readonly && !onChange) {
		throw new Error('Rating component is set to non-readonly, yet onChange is not defined.');
	}

	/**
	 * Handle changes to the radio button that backs the star rating selection
	 *
	 * @param {*} e
	 */
	const onInputChange = (e) => {
		if (onChange) {
			onChange(parseInt(e.currentTarget.value));
		}
	};

	if (readonly) {
		return (
			<>
				{ratingStars.map(({ id, isFull }) => (
					<React.Fragment key={id}>
						{isFull ? (
							<StarSVG data-testid="readonly-star-rating-full" className="roc-rating__icon" />
						) : (
							<StarEmptySVG
								data-testid="readonly-star-rating-empty"
								className="roc-rating__icon roc-rating__icon--empty"
							/>
						)}
					</React.Fragment>
				))}
			</>
		);
	}

	return (
		<>
			{ratingStars.map(({ id, isFull, isChecked, isPending, isHighlighted, ratingValue }) => {
				const modifierClasses = classnames({
					'roc-rating__input--full': isFull,
					'roc-rating__input--pending': isPending,
					'roc-rating__label--highlighted': isHighlighted,
				});

				return (
					<React.Fragment key={id}>
						<input
							type="radio"
							id={id}
							name={name}
							value={ratingValue}
							onChange={onInputChange}
							checked={isChecked}
							className={classnames('roc-rating__input', modifierClasses)}
							aria-describedby={name}
							data-testid={testId}
						/>
						<label
							className={classnames('roc-rating__label', modifierClasses)}
							htmlFor={id}
							onMouseEnter={() => setRatingHovered(ratingValue)}
							onMouseLeave={() => setRatingHovered(null)}
						>
							{isFull ? (
								<StarSVG className="roc-rating__icon" />
							) : (
								<StarEmptySVG className="roc-rating__icon roc-rating__icon--empty" />
							)}
						</label>
					</React.Fragment>
				);
			})}
		</>
	);
}

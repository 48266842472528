import React from 'react';
import useDelayedDisplay from 'shared/hooks/useDelayedDisplay';
import classnames from 'classnames';

type SpinnerAlignment = 'left' | 'right' | 'none';

interface SpinnerProps {
	light?: boolean;
	waitMs?: number;
	align?: SpinnerAlignment;
	block?: boolean;
	parentCentered?: boolean;
}

/**
 * Reusable roc-spinner component with color, alignment and wait time props.
 *
 * @export
 * @param {SpinnerProps} { light = false, waitMs = 200, align = 'right', block }
 * @returns
 */
export default function Spinner({
	light = false,
	waitMs = 200,
	align = 'right',
	block,
	parentCentered = false,
}: SpinnerProps) {
	const display = useDelayedDisplay(waitMs);

	if (!display) {
		return null;
	}

	const spinner = (
		<div
			className={classnames('roc-spinner', {
				'roc-spinner--align-none': align === 'none',
				'roc-spinner--light': light,
				'roc-spinner--block': block,
				'roc-spinner--left': align === 'left',
				'roc-spinner--right': align === 'right',
			})}
			data-testid="spinner"
		>
			<div className="roc-spinner__body">
				<div className="roc-spinner__cube1" />
				<div className="roc-spinner__cube2" />
			</div>
		</div>
	);

	if (parentCentered) {
		return <div className="roc-spinner__centered-parent">{spinner}</div>;
	}

	return spinner;
}

import React from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from './ErrorBoundary';
import { toCamelCase } from 'util/StringUtil';
import 'shared/polyfills/getAttributeNames';
import Spinner from 'shared/components/Spinner';

interface AttributeProps {
	[index: string]: string;
}

function getDataPropsFromAttributes(element: Element): AttributeProps {
	const props: AttributeProps = {};

	const dataAttributePrefix = 'data-';

	const attributeNames = element.getAttributeNames().filter((s) => s.startsWith(dataAttributePrefix));
	for (const attributeName of attributeNames) {
		const strippedName = toCamelCase(attributeName.substring(dataAttributePrefix.length));
		props[strippedName] = element.getAttribute(attributeName) ?? '';
	}

	return props;
}

/**
 * A helper function to facilitate initializing react applications on top of server-side rendered elements.
 * @param getReactElement Function that returns the react component to render.
 * @param elementId The ID of the DOM element being targeted.
 */
export const initReactApp = (getReactElement: (props: unknown) => React.ReactElement, elementId: string) => {
	if (typeof getReactElement !== 'function' || !elementId) {
		return () => {
			console.warn('A react element generator function and element ID are required.');
		};
	}

	const element = document.getElementById(elementId);

	if (!element) {
		return () => {
			console.warn(
				`Element with ID '${elementId}' was not found. Did you forget to render it on the server or is this entry point not supposed to run?`,
			);
		};
	}

	return () => {
		const props = getDataPropsFromAttributes(element);
		console.debug(`Rendering application inside element with ID ${elementId}.`);

		ReactDOM.render(
			<React.Suspense fallback={<Spinner />}>
				<ErrorBoundary>{getReactElement(props)}</ErrorBoundary>
			</React.Suspense>,
			element,
		);
	};
};

export const initReactAppByAttribute = (
	getReactElement: (props: any, domElement: Element) => React.ReactElement,
	attribute: string,
) => {
	if (typeof getReactElement !== 'function' || !attribute) {
		return () => {
			console.warn('A react element generator function and element ID are required.');
		};
	}

	const elements = document.querySelectorAll(`[${attribute}]`);

	if (!elements || elements.length == 0) {
		return () => {
			console.warn(
				`No elements with data attribute '${attribute}' were found. Did you forget to render it on the server or is this entry point not supposed to run?`,
			);
		};
	}

	return () => {
		for (let i = 0; i < elements.length; ++i) {
			const element = elements[i];
			const props = getDataPropsFromAttributes(element);
			console.debug(`Rendering application inside element with attribute ${attribute}.`);

			ReactDOM.render(
				<React.Suspense fallback={<Spinner />}>
					<ErrorBoundary>{getReactElement(props, element)}</ErrorBoundary>
				</React.Suspense>,
				element,
			);
		}
	};
};

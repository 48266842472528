import axios, { AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import AccountUrls from 'shared/util/AccountUrls';
import { redirect } from 'shared/util/Misc';
import { url } from 'util/Urls';
import { getCsrfToken } from './UserData';

/**
 * Helper that creates an instance of Axios to be used throughout the client application
 * and adds a default X-Requested-With header to all requests.
 *
 */
const AxiosHelper: AxiosInstance = axios.create();
AxiosHelper.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

AxiosHelper.interceptors.request.use(async (config) => {
	// Add CSRF tokens to all commands that are not GET, HEAD, or OPTIONS
	if (
		config.method?.toLowerCase() !== 'get' &&
		config.method?.toLowerCase() !== 'head' &&
		config.method?.toLowerCase() !== 'options'
	) {
		const csrfToken = await getCsrfToken();
		config.headers = { RequestVerificationToken: csrfToken, ...config.headers };
	}

	// ensure that url's are correctly generated prepending the subdirectory if it's present.
	if (!(config as CustomAxiosRequestConfig).bypassRocUrlGeneration) {
		config.url = url(config.url || '');
	}
	return config;
});

export default AxiosHelper;

export type CustomAxiosRequestConfig = AxiosRequestConfig & {
	bypassRocUrlGeneration?: boolean;
};

// eslint-disable-next-line no-unused-vars
export const addUnauthorizedAccessInterceptor = (history) => {
	// this allows us to act when any request going through axios succeeds or fails.
	AxiosHelper.interceptors.response.use(
		(response: AxiosResponse) => {
			return response;
		},
		(error: AxiosError) => {
			if (error.response && error.response.status === 401) {
				redirect(`${AccountUrls.Login}?ReturnUrl=${encodeURIComponent(window.location.pathname)}`);
			}

			return Promise.reject(error);
		},
	);
};

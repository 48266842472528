import { useEffect, useState } from 'react';

/**
 * The state of all of the stars being displayed, these change together frequently
 * so lets store them together. This is also slightly better than calculating
 * on every single render. Makes it easier to reuse as well.
 */
interface RatingStarState {
	id: string;
	isFull: boolean;
	isPending: boolean;
	isHighlighted: boolean;
	isChecked: boolean;
	ratingValue: number;
}

/**
 * The params for the useStarRating hook.
 */
interface UseStarRatingParams {
	value: number | null;
	name: string;
	maxRating?: number;
}

/**
 * The values returned by the custom star rating hook
 */
interface UseStarRatingResult {
	ratingStars: RatingStarState[];
	setRatingHovered: (value: number | null) => void;
}

/**
 * Custom hook that manages state for simple star rating implementations
 * @param params
 */
export default function useStarRating(params: UseStarRatingParams): UseStarRatingResult {
	const { value, name, maxRating = 5 } = params;
	const [ratingStars, setRatingStars] = useState<RatingStarState[]>([]);
	const [ratingHovered, setRatingHovered] = useState<number | null>(null);

	useEffect(() => {
		const newState: RatingStarState[] = [];

		for (let i = 1; i <= maxRating; i++) {
			const id = 'rating-group-' + name + '-' + i;
			const isFull = value !== null && value >= i;
			const isPending = ratingHovered ? i > ratingHovered && isFull : false;
			const isHighlighted = ratingHovered ? i <= ratingHovered : false;
			const isChecked = value === i;

			newState.push({
				id,
				isFull,
				isPending,
				isHighlighted,
				isChecked,
				ratingValue: i,
			});
		}

		setRatingStars(newState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, ratingHovered]);

	return { ratingStars, setRatingHovered };
}

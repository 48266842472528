import * as React from 'react';
import { toKebabCase } from 'util/StringUtil';
import { AutoGeneratedLocalizerResourceKeys } from './Localizer.autogenerated';
import { CustomLocalizerResourceKeys } from './Localizer.custom';

/**
 * Represents every legal localization key in the application.
 *
 * Note that the client must have received the ResourceKey-Translation pair in order to translate a specific resource key into the current
 * locale. If this ResourceKey-Translation pair wasn't received, the raw resource key is displayed instead and a warning is logged in the console.
 */
export type LocalizerResourceKey = AutoGeneratedLocalizerResourceKeys | CustomLocalizerResourceKeys;

/**
 * Interface for the resources
 *
 * @export
 * @interface LocalizerResource
 */
interface LocalizerResource {
	[id: string]: string;
}

/**
 * Extends Window with a property that will hold the localizer resources
 *
 * @interface WindowWithResources
 * @extends {Window}
 */
export interface WindowWithResources extends Window {
	__ROC_LOCALIZER_RESOURCES__?: LocalizerResource[];
}

/**
 * Main object that will hold all the localized resources
 */
const LocalizerResources: LocalizerResource = {};

/**
 * To prevent redundant logging
 */
const isAlreadyLogged: Record<string, boolean> = {};

/**
 * Initializer function that will populate the main localized resources object
 */
function initializeLocalizerResources() {
	const windowWithResources = window as WindowWithResources;

	if (
		windowWithResources &&
		windowWithResources.__ROC_LOCALIZER_RESOURCES__ &&
		Array.isArray(windowWithResources.__ROC_LOCALIZER_RESOURCES__)
	) {
		windowWithResources.__ROC_LOCALIZER_RESOURCES__.forEach((obj) => {
			for (const key of Object.keys(obj)) {
				LocalizerResources[key] = obj[key];
			}
		});
	}
}

/**
 * Transforms the given resource key and argument list into a translated string in the current locale.
 *
 * @export
 * @param {string} key
 * @param {...string[]} replaceStrings
 * @returns
 */
export function Localizer<TResourceKey extends LocalizerResourceKey = LocalizerResourceKey>(
	key: TResourceKey,
	...replaceStrings: string[]
) {
	if (Object.keys(LocalizerResources).length === 0) {
		initializeLocalizerResources();
	}

	if (!LocalizerResources || !LocalizerResources[key]) {
		// if running in a test environment, just skip any warnings.
		if (process.env.NODE_ENV !== 'test') {
			if (!isAlreadyLogged[key]) {
				console.warn(key, 'Localizer Resource not found. Key will be used instead.');
				isAlreadyLogged[key] = true;
			}
		}
		return key;
	}

	const resource = LocalizerResources[key];

	if (!replaceStrings) {
		return resource;
	}

	return resource.replace(/{(\d+)}/g, (match: string, index: number) => {
		return replaceStrings[index] ? replaceStrings[index] : '';
	});
}

interface LocalizedSpanProps {
	resourceKey: LocalizerResourceKey;
	args?: string[];
}

export function LocalizedSpan(props: LocalizedSpanProps) {
	const { resourceKey, args = [] } = props;
	const localizedString = Localizer(resourceKey, ...args);

	return (
		<span
			data-testid={`localized-${toKebabCase(resourceKey)}`}
			dangerouslySetInnerHTML={{ __html: localizedString }}
		/>
	);
}

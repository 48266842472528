import { SiteSwitcherServerData } from 'features/header/types';
import { ExternalLoginProvider } from 'features/login/types';
import { CurrentSiteHost } from 'features/sites/CurrentSiteHost';

/**
 * Extends Window with a property that will hold the client data
 *
 * @interface WindowWithClientData
 * @extends {Window}
 */
interface WindowWithServerData extends Window {
	__ROC_SERVER_DATA__?: Array<{
		[name: string]: string;
	}>;
}

/**
 * This type will hold all the keys that are used by ServerData
 */
export interface ServerData {
	IsReCaptchaEnabled?: boolean;
	ReCaptchaV3Key?: string;
	IsUserLoginReCaptchaEnabled?: boolean;
	IsContactFormReCaptchaEnabled?: boolean;
	IsFaqReCaptchaEnabled?: boolean;
	IsUserForgotPasswordReCaptchaEnabled?: boolean;
	IsUserRegisterReCaptchaEnabled?: boolean;
	IsUserResetPasswordReCaptchaEnabled?: boolean;
	IsMyAccountAddressBookReCaptchaEnabled?: boolean;
	IsMyAccountWalletReCaptchaEnabled?: boolean;
	// #region Commerce
	IsAddToCartReCaptchaEnabled?: boolean;
	IsOrderStatusReCaptchaEnabled?: boolean;
	IsSubmitReviewReCaptchaEnabled?: boolean;
	IsCheckoutAddressBookReCaptchaEnabled?: boolean;
	IsCheckoutWalletReCaptchaEnabled?: boolean;
	IsCheckoutShippingMethodReCaptchaEnabled?: boolean;
	IsCheckoutPlaceOrderReCaptchaEnabled?: boolean;
	// #endregion

	TimeZone?: string;
	USE_EMAIL_AS_USERNAME?: boolean;
	RegistrationThankYouTitle?: string;
	RegistrationThankYouMessage?: string;
	ForgotPasswordConfirmationTitle?: string;
	ForgotPasswordConfirmationMessage?: string;
	PageTitleSuffix?: string;
	RequireConfirmEmail?: boolean;
	ACCOUNT_URL_BASE?: string;
	ACCOUNT_LOGIN_URL?: string;
	ACCOUNT_LOGOUT_URL?: string;
	ACCOUNT_REGISTER_URL?: string;
	ACCOUNT_LOGIN_WITH_2FA_URL?: string;
	ACCOUNT_FORGOT_PASSWORD_URL?: string;
	ACCOUNT_LOGIN_WITH_RECOVERY_CODE_URL?: string;
	EXTERNAL_LOGIN_PROVIDERS?: ExternalLoginProvider[];
	CURRENT_SITE_HOST?: CurrentSiteHost;
	ASSET_HOST?: string | null;
	PAGE_TYPE?: string;
	IsGooglePlacesEnabled?: boolean;
	RESPONSIVE_IMAGE_BREAKPOINTS?: Record<string, number>;
	ACCESS_CONTROL_ENABLED?: boolean;
	SITE_SWITCHER?: SiteSwitcherServerData;
	CurrentCurrency?: string;

	// #region Commerce
	ACCOUNT_ORGANIZATION_REVIEW_URL?: string;
	ACCOUNT_ORGANIZATION_SELECTOR_URL?: string;
	EnableGoogleGeocoding?: boolean;
	EnableGoogleMaps?: boolean;
	GoogleMapsApiKey?: string; // used in Store Locator
	MinimumTimeBeforeRecentlyViewedProductsAreValidated?: number;
	QuickOrderItemMinumumSearchCharacters?: number;
	MaxAllowedQuickOrderItems?: number;
	MaxNumberForQuantityDropdown?: number;
	EnableStoreLocator?: boolean;
	OrderDetailsUIComponent?: string;
	GuestCheckoutEnabled?: boolean;
	EnableDecimalQuantities?: boolean;
	EnableMinimumOrderQuantities?: boolean;
	EnableMultipleUserOrganizations?: boolean;
	// #endregion

	// #region EFG
	EnableCheckoutReviewStep?: boolean;
	ShopPlansUrlPage?: string;
	SimplicityProtectionBody?: string;
	NoCoverageUrlPage?: string;
	ATTRIBUTES_NAME_AGE_OF_PROPERTY?: string;
	ATTRIBUTES_NAME_PROPERTY_SIZE?: string;
	OrderConfirmationSocialLinks?: string;
   // #endregion
}

/**
 * Main object that will hold all the client data
 */
export const ServerData: ServerData = {};

/**
 * Initializer function that will populate the main settings object
 */
function initializeServerData() {
	const windowWithServerData = window as WindowWithServerData;

	if (
		windowWithServerData &&
		windowWithServerData.__ROC_SERVER_DATA__ &&
		Array.isArray(windowWithServerData.__ROC_SERVER_DATA__)
	) {
		for (const obj of windowWithServerData.__ROC_SERVER_DATA__) {
			for (const key of Object.keys(obj)) {
				ServerData[key] = obj[key];
			}
		}
	}
}

initializeServerData();

/**
 * Returns the current value from ASSET_HOST, or an empty string if none is defined by the server.
 */
export function getAssetHost(): string {
	return ServerData.ASSET_HOST ?? '';
}

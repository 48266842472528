import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * Star Empty SVG
 *
 * @returns
 */
function StarEmptySVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 34 32"
			className={classnames('roc-icon roc-icon-star-empty', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path d="M16.941 23.757l-9.958 6.89 3.476-11.6-9.63-7.341 12.106-0.279 4.006-11.427 4.006 11.427 12.106 0.279-9.63 7.341 3.476 11.599z" />
		</svg>
	);
}

export default StarEmptySVG;

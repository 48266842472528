import React from 'react';
import { SVGProps } from './SVGProps';
import classnames from 'classnames';

/**
 * X SVG
 *
 * @returns
 */
function XSVG({ className, ...rest }: SVGProps) {
	return (
		<svg
			viewBox="0 0 1024 1024"
			className={classnames('roc-icon roc-icon-x', className)}
			focusable="false"
			aria-hidden="true"
			{...rest}
		>
			<path d="M984.346 32.674c31.139 31.139 31.139 81.625 0 112.764l-375.943 375.851 375.943 375.912c31.139 31.139 31.139 81.625 0 112.764s-81.625 31.139-112.764 0l-375.892-375.89-375.871 375.89c-31.139 31.139-81.625 31.139-112.764 0s-31.139-81.625 0-112.764l375.849-375.912-375.849-375.851c-31.139-31.139-31.139-81.625 0-112.764s81.625-31.139 112.764 0l375.871 375.829 375.892-375.829c31.139-31.139 81.625-31.139 112.764 0z" />
		</svg>
	);
}

export default XSVG;

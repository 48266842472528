import React, { useState, useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import uuid from 'uuid/v4';
import 'tiny-slider/src/tiny-slider.scss';

import type { TinySliderInstance, TinySliderSettings } from 'tiny-slider';

interface TinySliderProps {
	options: TinySliderSettings;
	lazyLoad: boolean;
	className?: string;
	dependencies?: any;
	children: React.ReactNode;
}

const customControlsMarkup = [
	`
    <span class="roc-visually-hidden">Previous Slide</span>
    <svg class="efg-slider-icon" aria-hidden="true" focusable="false"><use xlink:href="#icon-efg-slider-arrow-left"></use></svg>
    `,
	`
    <span class="roc-visually-hidden">Next Slide</span>
    <svg class="efg-slider-icon" aria-hidden="true" focusable="false"><use xlink:href="#icon-efg-slider-arrow-right"></use></svg>
    `,
];

const TinySlider = ({ options, lazyLoad, className, dependencies, children }: TinySliderProps) => {
	const [showSlider, setShowSlider] = useState<boolean>(lazyLoad ? false : true);

	const [ref, inView] = useInView({ threshold: 0, triggerOnce: true });

	const sliderContainerId = useRef('tiny-slider-instance-' + uuid()).current;
	const tinySliderRef = useRef<TinySliderInstance | null>(null);

	const tnsPromise = import('tiny-slider/src/tiny-slider').then((module) => module.tns);

	const initSlider = useRef(async () => {
		const sliderContainerElement = document.getElementById(sliderContainerId);

		if (sliderContainerElement === null) {
			console.warn('No container for the slider was found.');
			return;
		}

		const tns = await tnsPromise;

		const tinySliderOptions: TinySliderSettings = {
			container: sliderContainerElement,
			...options,
			controlsText: customControlsMarkup,
		};

		tinySliderRef.current = tns(tinySliderOptions);
	});

	useEffect(() => {
		if (lazyLoad && inView) {
			setShowSlider(true);
		}
	}, [inView, lazyLoad]);

	useEffect(() => {
		if (showSlider === true) {
			(async () => {
				if (tinySliderRef.current !== null) {
					tinySliderRef.current.destroy();
					tinySliderRef.current = null;
				}

				await initSlider.current();
			})();
		}

		return () => {
			if (tinySliderRef.current !== null) {
				tinySliderRef.current.destroy();
				tinySliderRef.current = null;
			}
		};
	}, [dependencies, showSlider]);

	if (showSlider === false) {
		return <div ref={ref} />;
	}

	return (
		<div id={sliderContainerId} className={className}>
			{children}
		</div>
	);
};

export default TinySlider;

import { ServerData } from 'services/ServerData';

/**
 * Removes duplicate slashes from the URL
 * @param url The URL that will have duplicate slashes removed
 */
export function cleanDuplicateSlashes(url: string) {
	return url.replace(/\/+/g, '/');
}

/**
 * Generates a relative URL with the current site host's subdirectory, if applicable, and cleans the URL by removing duplicate slashes.
 * @param path
 */
export function url(path: string): string {
	return uri(path).toString();
}

/**
 * Generates a relative URI with the current site host's subdirectory, if applicable, and cleans the URL by removing duplicate slashes.
 * @param path
 */
export function uri(path: string): Uri {
	const [_uri, isOriginalAbsolute] = internalAbsoluteUri(path);
	if (isOriginalAbsolute) {
		return _uri;
	}
	return new Uri(_uri.pathAndQuery || '/');
}

/**
 * Generates an absolute URL with the current site host's port and subdirectory, if applicable.
 * @param path
 */
export function absoluteUrl(path: string): string {
	const [_uri] = internalAbsoluteUri(path);
	return _uri.toString();
}

/**
 * Generates an absolute URI with the current site host's port and subdirectory, if applicable.
 * @param path
 */
function internalAbsoluteUri(path: string): [Uri, boolean] {
	if (!path || path === '') {
		throw 'Path cannot be empty, use "/" if you meant to go to the site root.';
	}

	if (!ServerData.CURRENT_SITE_HOST) {
		throw 'Current site host has not been initialized!';
	}

	let uri = new Uri(path);
	const isAbsoluteUri = uri.isAbsoluteUri;

	if (!isAbsoluteUri && !path.startsWith('/')) {
		throw 'Path must begin with a "/" character.';
	}

	let isCurrentHost = false;
	if (!uri.isAbsoluteUri) {
		isCurrentHost = true;
		uri = new Uri(
			'https' +
				'://' +
				ServerData.CURRENT_SITE_HOST.Host +
				(ServerData.CURRENT_SITE_HOST.Port && ServerData.CURRENT_SITE_HOST.Port > 0
					? ':' + ServerData.CURRENT_SITE_HOST.Port
					: '') +
				path,
		);
	}

	const isExternalUri = !isCurrentHost || uri.host.toLowerCase() != ServerData.CURRENT_SITE_HOST.Host.toLowerCase();

	if (!isExternalUri) {
		const lastSegment = uri.segments[uri.segments.length - 1];
		const cleanedLastSegment =
			lastSegment.endsWith('/') && !ServerData.CURRENT_SITE_HOST.TrailingSlash
				? lastSegment.substring(0, lastSegment.length - 1)
				: !lastSegment.endsWith('/') && ServerData.CURRENT_SITE_HOST.TrailingSlash
				? lastSegment + '/'
				: lastSegment;

		const subdirectory =
			!ServerData.CURRENT_SITE_HOST.Subdirectory || ServerData.CURRENT_SITE_HOST.Subdirectory === ''
				? ''
				: '/' + ServerData.CURRENT_SITE_HOST.Subdirectory;

		const segments =
			lastSegment !== cleanedLastSegment
				? (uri.segments.length > 1 ? uri.segments.slice(0, uri.segments.length - 1).join('') : '') +
				  cleanedLastSegment
				: uri.segments.join('');
		const newUrl =
			uri.scheme +
			'://' +
			uri.host +
			(uri.port && uri.port > 0 ? ':' + uri.port : '') +
			subdirectory +
			segments +
			(uri.query ? uri.query : '');
		uri = new Uri(newUrl);
	}

	return [uri, isAbsoluteUri];
}

/**
 * Represents an absolute or relative URI mimicing the C# equivalent.
 */
export class Uri {
	readonly isDefaultPort: boolean;
	readonly host: string;
	readonly isAbsoluteUri: boolean;
	readonly segments: string[];
	readonly originalString: string;
	readonly pathAndQuery: string;
	readonly port?: number;
	readonly query: string;
	readonly scheme?: string;
	readonly absoluteUri: string;
	readonly path: string;

	constructor(path: string) {
		this.originalString = path;

		const lowerPath = path.toLowerCase();
		const isHttp = lowerPath.startsWith('http://');
		const isHttps = lowerPath.startsWith('https://');

		this.isAbsoluteUri = isHttp || isHttps;
		this.scheme = isHttp ? 'http' : isHttps ? 'https' : undefined;

		if (this.isAbsoluteUri) {
			const pathLessScheme = path.substring(isHttp ? 7 : 8);
			const cleanPath = cleanDuplicateSlashes(pathLessScheme);
			const hostPathSegments = cleanPath.split('/');

			const hostWithPort = hostPathSegments[0];
			const hostSegments = hostWithPort.split(':');
			this.host = hostSegments[0];
			this.port = hostSegments.length > 1 ? Number(hostSegments[1]) : undefined;
			this.isDefaultPort = !this.port;

			if (hostPathSegments.length > 1) {
				this.pathAndQuery = '/' + hostPathSegments.slice(1).join('/');
				const pathSegments = this.pathAndQuery.split('?');
				this.path = pathSegments[0];
				if (pathSegments.length > 1) {
					this.query = '?' + pathSegments.slice(1).join('');
				}
				this.segments = this.path
					.split('/')
					.slice(1)
					.map((s) => '/' + s);
			}

			this.absoluteUri = this.toString();
		} else {
			const cleanPath = cleanDuplicateSlashes(path);
			this.pathAndQuery = cleanPath;
			const pathSegments = this.pathAndQuery.split('?');
			this.path = pathSegments[0];
			if (pathSegments.length > 1) {
				this.query = '?' + pathSegments.slice(1).join('');
			}
			this.segments = this.path
				.split('/')
				.slice(1)
				.map((s) => '/' + s);
		}
	}

	toString(): string {
		if (this.isAbsoluteUri) {
			return (
				this.scheme +
				'://' +
				this.host +
				(this.isDefaultPort ? '' : ':' + this.port) +
				(this.pathAndQuery ? this.pathAndQuery : '')
			);
		}
		return this.pathAndQuery;
	}
}

import React, { useEffect, useState } from 'react';
import AxiosHelper from '../../../services/AxiosHelper';
import StarRating from '../../../shared/components/star-rating/StarRating';
import TinySlider from '../../../shared/components/efg/TinySlider';
import Spinner from 'shared/components/Spinner';

import type { TinySliderSettings } from 'tiny-slider';

interface Testimonials {
	testimonials: Testimonial[];
}

interface Testimonial {
	id: string;
	reviewerName: string;
	source?: string;
	stars: number;
	bodyContent: string;
}

const options: TinySliderSettings = {
	items: 1,
	slideBy: 1,
	controls: true,
	nav: false,
	mouseDrag: true,
	gutter: 15,
	lazyload: true,
	loop: false,
	responsive: {
		767: {
			items: 2,
		},
		1280: {
			items: 3,
		},
	},
	onInit() {
		const sliderControls = document.querySelector<HTMLElement>('.tns-controls');

		if (sliderControls === null) {
			console.warn('No controls for the slider were found.');
			return;
		}

		sliderControls.removeAttribute('tabindex');

		const sliderControlBtns = Array.from(sliderControls.querySelectorAll<HTMLElement>('button[data-controls]'));

		if (sliderControlBtns.length === 0) {
			console.warn('No control buttons were found for the slider.');
			return;
		}

		for (const btn of sliderControlBtns) {
			btn.removeAttribute('tabindex');
		}
	},
};

export default function Testimonial() {
	const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
	const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

	useEffect(() => {
		(async () => {
			if (isInitialLoad) {
				try {
					const response = await AxiosHelper.get<Testimonials>('/ajax/testimonial');

					if (response?.data?.testimonials) {
						setTestimonials(response.data.testimonials);
						setIsInitialLoad(false);
					}
				} catch (err) {
					console.error('Unable to load testimonials.');
				}
			}
		})();
	}, [isInitialLoad]);

	if (isInitialLoad && testimonials.length === 0) {
		return <Spinner />;
	}

	return (
		<TinySlider options={options} lazyLoad={false} className="testimonial-slider-wrap">
			{testimonials?.map((f, index) => (
				<div className="testimonial-top-div" key={'testimonial-top-div' + index}>
					<div className="testimonial-top-div-inner">
						<div className="fg-quote-symbol">
							<svg className="efg-quote-icon" aria-hidden="true" focusable="false">
								<use xlinkHref="#icon-efg-quote"></use>
							</svg>
						</div>
						<div
							className="testimonial-slider-content"
							dangerouslySetInnerHTML={{ __html: f.bodyContent }}
						/>
						<div className="testimonial-slider-bottom">
							<div className="star-rating-wrap" aria-label={`${f.stars} out of 5 stars`}>
								<StarRating value={f.stars} name="" testId={`review-star-rating-${f.id}`} readonly />
							</div>
							<h2 className="reviewername-title">{f.reviewerName}</h2>
							{/* {f.reviewerName + ' | ' + f.source} */}
						</div>
					</div>
				</div>
			))}
		</TinySlider>
	);
}

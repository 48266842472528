import { useState, useEffect } from 'react';

/**
 * Reusable hook that returns true after a certain amount of time has passed. Useful to delay
 * displaying components in render functions.
 *
 * @export
 * @param {number} [waitMs=200]
 * @returns
 */
export default function useDelayedDisplay(waitMs: number = 200) {
	const [display, setDisplay] = useState(false);

	useEffect(() => {
		// after a set amount of time, show the spinner
		const timeout = setTimeout(() => {
			setDisplay(true);
		}, waitMs);

		return () => {
			clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return display;
}

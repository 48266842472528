import React from 'react';
import InlineAlert from 'shared/edit/InlineAlert';

// eslint-disable-next-line
// tslint:disable-next-line
export interface ErrorBoundaryRenderProps extends ErrorBoundaryState {}

export interface ErrorBoundaryProps {
	children: React.ReactNode;
	renderError?: (renderProps: ErrorBoundaryRenderProps) => React.ReactNode;
}

export interface ErrorBoundaryState {
	error?: Error;
}

/**
 * Default Error Boundary component for frontend components, so that in case a JS error occurs, we show
 * something instead of nothing.
 */
export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	public static defaultProps = {
		renderError: (renderProps: ErrorBoundaryRenderProps) => <InlineAlert error={renderProps.error ?? null} />,
	};

	public state: ErrorBoundaryState = {};

	public componentDidCatch(error: Error) {
		if (error.name === 'ChunkLoadError') {
			const lastRefreshedTimestamp = new Date(
				parseInt(window.localStorage.getItem('force-refresh-timestamp') || '0'),
			);
			const pageAlreadyRefreshedLessThan30SecondsAgo =
				new Date(lastRefreshedTimestamp.getTime() + 30 * 1000) > new Date();

			if (!pageAlreadyRefreshedLessThan30SecondsAgo) {
				// Assuming that the user is not on the latest version of the application. Refresh the page immediately.
				window.localStorage.setItem('force-refresh-timestamp', new Date().getTime().toString());
				return window.location.reload();
			}
		}

		this.setState({
			error,
		});
	}

	public render() {
		if (this.state.error) {
			return this.props.renderError!(this.state);
		}

		return this.props.children;
	}
}

import React from 'react';
import Testimonial from './Testimonial';
import { initReactApp } from 'shared/util/ApplicationInit';

const render = initReactApp(() => <Testimonial />, 'testimonial-listing');

render();

// optional, use this to enable hot reloading
if ((module as any).hot) {
	(module as any).hot.accept('./Testimonial', () => {
		render();
	});
}
